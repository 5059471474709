<template>
  <Teleport to="body">
    <sweet-modal
      ref="modal"
      v-sweet-esc
      modal-theme="right theme-wide"
      @close="verticalContainerClass"
      @open="verticalContainerClass"
    >
      <template slot="title">
        <div class="d-flex align-items-center">
          <img
            src="https://nulodgic-static-assets.s3.amazonaws.com/images/faq.png"
            height="40"
            class="mr-2 ml-n3"
          >
          <h4 class="mb-0">FAQ</h4>
        </div>
      </template>

      <template slot="box-action">
        <div
          class="sweet-action-close"
          @click="toggleFullscreen" 
        >
          <i :class="fullScreenIconClass"/>
        </div>
      </template>

      <div class="row">
        <div class="col-12">
          <div>
            <faq-item
              :faq-obj="faq"
              :truncate-title="false"
              :truncate-body="false"
            />
          </div>
        </div>
      </div>
    </sweet-modal>
  </Teleport>
</template>

<script>
  import { SweetModal } from 'sweet-modal-vue';
  import multiCompany from 'mixins/multi_company';
  import FaqItem from './faq_help_center_portal.vue';

  export default {
    components: {
      SweetModal,
      FaqItem,
    },
    mixins:  [ multiCompany ],
    props: {
      faq: {
        type: Object,
        default: Boolean,
      },
    },
     data() {
      return {
        isFullscreen: false,
      };
    },
    computed: {
      modalTitle() {
        return this.faq?.questionBody || 'FAQ';
      },
      fullScreenIconClass() {
        return {
          'genuicon-resize-down': this.isFullscreen,
          'genuicon-expand': !this.isFullscreen,
        };
      },
    },
    methods: {
      open() {
        this.$refs.modal.open();
        this.isFullscreen = false;
      },
      close() {
        this.$refs.modal.close();
      },
      toggleFullscreen() {
        const { classList } = this.$refs.modal.$el;
        this.isFullscreen = !this.isFullscreen;
        if (this.isFullscreen) {
          classList.add('theme-full');
        } else {
          classList.remove('theme-full');
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  :deep(.sweet-modal) {
    .sweet-title {
      align-items: center;
      display: flex;
    }
  }
</style>
