import http from 'common/http';

export default {
  data() {
    return {
      companySubscriptions: [],
      isLoading: true,
    };
  },
  computed: {
    hasFullPlatformSubscription() {
      return this.hasSubscription('full_platform');
    },
    hasAssetManagementSubscription() {
      return this.hasSubscription('asset_management');
    },
    hasVendorManagementSubscription() {
      return this.hasSubscription('vendor_management');
    },
    hasHelpDeskSubscription() {
      return this.hasSubscription('it_help_desk');
    },
    subscriptionConditions() {
      return this.hasFullPlatformSubscription || this.$hasFreeTrial || this.$superAdminUser || this.$activeLegacyCompany;
    },
  },
  mounted() {
    this.getCompanySubscription();
  },
  methods: {
    hasSubscription(moduleType) {
      return this.companySubscriptions.some(subscription =>
        subscription.status !== 'insolvent' &&
        subscription.moduleType === moduleType
      );
    },
    getCompanySubscription() {
      const path = document.location.pathname;
      if (path.startsWith('/help_center')) {
        return;
      }
      http
        .get('/subscriptions.json', {
          params: {
            privilege_name: this.moduleName,
          },
        })
        .then(({ data: { subscriptions } }) => {
          this.companySubscriptions = subscriptions;
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
          this.emitError(`Sorry, there was an error retrieving your subscription. ${error.response.data.message}`);
        });
    },
    filterPermissions(permissions) {
      let filteredPermissions = permissions;
      if (!(this.subscriptionConditions || this.hasVendorManagementSubscription)) {
        filteredPermissions = filteredPermissions.filter(item => item !== "Contract" && item !== "Vendor");
      }
      if (!(this.subscriptionConditions || this.hasAssetManagementSubscription)) {
        filteredPermissions = filteredPermissions.filter(item => item !== "ManagedAsset");
      }
      if (!(this.subscriptionConditions || this.$showFreeModules)) {
        filteredPermissions = filteredPermissions.filter(item => item !== "TelecomService" && item !== "Monitoring");
      }
      return filteredPermissions;
    },
    filterSubscriptions(fields) {
      const fieldsToRemove = new Set(['asset_list', 'contract_list', 'vendor_list']);
      if (this.hasFullPlatformSubscription || this.subscriptionConditions) {
        fieldsToRemove.clear();
      } else {
        if (this.hasAssetManagementSubscription) {
          fieldsToRemove.delete('asset_list');
        }
        if (this.hasVendorManagementSubscription) {
          fieldsToRemove.delete('contract_list');
          fieldsToRemove.delete('vendor_list');
        }
      }
      return fields.filter(field => !fieldsToRemove.has(field.fieldAttributeType));
    },
  },
};
