<template>
  <help-center
    v-if="helpCenterModernDesignEnabled && isValidWorkspace"
    :workspaces="workspaces"
  />
  <div
    v-else
    class="open-ticket-portal-bg"
    :style="{ 'background-color': helpCenterPortalBGColor }"
  >
    <div
      v-if="forms"
      class="row justify-content-center"
    >
      <div class="col-md-8 col-lg-6 mt-6">
        <div class="box px-5">
          <div
            v-if="forms.length > 0"
            class="box__inner"
          >
            <div class="d-flex justify-content-between">
              <div class="d-flex flex-column jusify-items-center pl-0 mb-4">
                <div class="d-flex align-items-center mb-1 mb-1">
                  <img
                    src="https://nulodgic-static-assets.s3.amazonaws.com/images/company-icon.svg"
                    class="company-select__icon mr-1"
                    alt="Company Icon"
                    height="16"
                    width="16"
                  >
                  <span class="not-as-small text-muted d-inline-block">Company:</span>
                  <span class="text-secondary font-weight-semi-bold not-as-small ml-1">{{ $companyName }}</span>
                </div>
                <div class="d-flex align-items-center">
                  <img
                    src="https://nulodgic-static-assets.s3.amazonaws.com/images/workspaces-icon.svg"
                    class="workspace-header__label--image d-inline-block workspace-select__icon mr-1"
                    alt="Workspaces Icon"
                    height="16"
                    width="16"
                  >
                  <span class="not-as-small text-muted d-inline-block">Workspace:</span>
                  <span
                    class="text-secondary font-weight-semi-bold not-as-small ml-1"
                    :data-tc-view-select-space="workspace.name"
                  >{{ workspace.name }}</span>
                </div>
              </div>
              <router-link
                v-if="showBack"
                :to="`/?back=true`"
                class="text-secondary mr-0"
                data-tc-back
              >
                <i class="nulodgicon-arrow-left-c white mr-2" />
                <span>Back to <strong>Workspaces</strong></span>
              </router-link>
            </div>
            <div class="form-selection-header font-weight-normal text-dark not-as-small mb-3 mt-2 pt-1">
              <div class="d-flex align-items-center">
                <span>Select a Form</span>
              </div>
            </div>
            <div>
              <div
                v-for="form in forms"
                :key="form.id"
              >
                <a
                  href="#"
                  class="btn btn-sm btn-link text-secondary text-left py-2 mb-2 border border-light rounded workspace-link w-100"
                  :data-tc-select-ticket-form="form.name"
                  @click="selectForm(form)"
                >
                  <img
                    src="https://nulodgic-static-assets.s3.amazonaws.com/images/asset_manual_entry_icon.svg"
                    class="workspace-header__label--image d-inline-block form-select__icon mr-1 mb-1"
                    alt="Workspaces Icon"
                    height="14"
                    width="14"
                  >
                  {{ form.name }}
                </a>
              </div>
            </div>
          </div>
          <div
            v-else
            class="box__inner"
          >
            <div class="mt-5">
              <p>I'm sorry but this workspace is not available any longer.</p>
              <p class="not-as-small">
                In order to make a workspace available in your Help Center , log in and go to: <br> <span class="bg-light rounded">Help Desk</span> > <span class="bg-light rounded">Settings</span> > <span class="bg-light rounded">Custom Forms</span>.
              </p>
              <p class="not-as-small">
                Select a form, toggle 'Help Center' and check <span class="bg-light rounded">Allow this form in the Help Center Portal</span>. Save and voilà!
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import http from 'common/http';
  import { mapGetters } from 'vuex';
  import helpCenterPortalHelper from 'mixins/help_center_portal_helper';
  import HelpCenter from './help_center.vue';

  export default {
    components: {
      HelpCenter,
    },
    mixins: [helpCenterPortalHelper],
    props: [ 'workspaces' ],
    data() {
      return {
        forms: null,
        helpCenterPortalBGColor: null,
        isValidWorkspace: false,
      };
    },
    computed: {
      ...mapGetters(['helpCenterModernDesignEnabled']),
      showBack() {
        const company = getCompanyFromStorage();
        return !company?.isolateWorkspaces && this.workspaces && this.workspaces.length > 1;
      },
      workspace() {
        const workspace = getWorkspaceFromStorage();
        return workspace;
      },
    },
    mounted() {
      if (!this.helpCenterModernDesignEnabled) {
        this.fetchCustomForms();
      }
      const workspace = this.workspaces.find(w => w.id === parseInt(this.$route.params.workspace_id, 10));
      if (!workspace?.id) {
        this.forms = [];
        return;
      }
      this.isValidWorkspace = true;
    },
    methods: {
      selectForm(form) {
        this.$router.push(`/workspaces/${this.workspace.id}/forms/${form.id}`);
      },
      fetchCustomForms() {
        const params = {
          company_module: 'helpdesk',
          portal: true,
          active: true,
        };
        const workspace = this.workspaces.find(w => w.id === parseInt(this.$route.params.workspace_id,10));
        if (!workspace || !workspace.id) {
          this.forms = [];
          return;
        }
        this.forms = null;
        http
          .get(`/custom_form_options.json`, { params })
          .then((res) => {
            this.forms = res.data;
            if (this.forms && this.forms.length === 1 &&
                this.workspaces && this.workspaces.length === 1) {
              this.selectForm(this.forms[0]);
            }
            this.fetchBGColor();
          }) 
          .catch((error) => {
            this.emitError(`Sorry, there was an error loading workspaces. ${error.response.data.message}.`);
          });
      },
    },
  };
</script>

<style lang="scss">
  .open-ticket-portal-bg {
    height: calc(100vh - 107px);
  }
</style>
