import http from "common/http";
import _get from 'lodash/get';
import defaultStates from "./mixins/defaultStates";

export default {
  state: {
    workspaceOptions: [],
    contributorOptions: { options: {}, disableShowMore: {}, assignedToFieldOptions: [], selectedIds: [] },
    contributorOptionsWithGuests: { options: {}, disableShowMore: {} },
    errorMessage: null,
    mspCustomFormModule: '',
    customFormOptions: [],
    categories: [],
    departments: [],
    companyFilter: [],
    defaultCategories: [],
    defaultDepartments: [
      { id:1, name: "Marketing"},
      { id:2, name: "Sales"},
      { id:3, name: "Engineering"},
      { id:4, name: "Managment"},
      { id:5, name: "Maintenance"},
      { id:6, name: "HR"},
      { id:7, name: "Finance"},
      { id:8, name: "Legal"},
    ],
    currentCompanyUser: null,
    mfaSettings: defaultStates.mfaSettings(),
    secureGroups: [],
    awsRegions: [],
    currentVendor: null,
    nestedModalOpened: false,
    runCaptcha: Vue.prototype.$runCaptcha,
    isRobot: Vue.prototype.$runCaptcha,
    recaptchaSiteKey: Vue.prototype.$recaptchaSiteKey, // default to true when captcha is set to run
    company: {
      name: '',
      url: '',
      subdomain: '',
    },
    domain: null,
    reroutePath: null,
    stringInterpolationKeys: [],
    syncingConnectors: [],
    totalLocationsCount: 0,
    locations: [],
    locationLoader: false,
    userCompanies: [],
    selectedCompanies: [],
    dashboardView: localStorage.getItem('dashboardView') || 'management',
    mspData: {
      // people: [],
      // groups: [],
      categories: [],
      customForms: [],
      assetTypes: [],
      automatedTasks: [],
      responses: [],
      documents: [],
      faqs: [],
      blockedKeywords: [],
    },
    quickBuildData: {
      categories: [],
      customForms: [],
      assetTypes: [],
      automatedTasks: [],
      responses: [],
      documents: [],
      faqs: [],
      blockedKeywords: [],
    },
    loadedMspData: {
      // fetchUsers: false,
      // fetchMspGroups: false,
      fetchMspCategories: false,
      fetchMspCustomForms: false,
      fetchMspAssetTypes: false,
      fetchMspAutomatedTasks: false,
      fetchMspSnippets: false,
      fetchMspDocuments: false,
      fetchMspFaqs: false,
      fetchMspBlockedKeywords: false,
    },
    companyTemplates: [],
    loadingTemplates: true,
    isVerticalNav: false,
    selectedCardData: [],
    isEntityCreated: false,
  },
  mutations: {
    setWorkspaceOptions: (state, value) => {
      state.workspaceOptions = value;
    },
    setContributorOptions(state, contributorOptions) {
      state.contributorOptions.options = {
        ...state.contributorOptions.options,
        ...contributorOptions.options,
      };

      state.contributorOptions.disableShowMore = {
        ...state.contributorOptions.disableShowMore,
        ...contributorOptions.disableShowMore,
      };
      state.contributorOptions.assignedToFieldOptions = contributorOptions.assignedToFieldOptions;
      state.contributorOptions.selectedIds = contributorOptions.selectedIds;
    },
    setContributorOptionsWithGuests(state, options) {
      state.contributorOptionsWithGuests.options = {
        ...state.contributorOptionsWithGuests.options,
        ...options.options,
      };

      state.contributorOptionsWithGuests.disableShowMore = {
        ...state.contributorOptionsWithGuests.disableShowMore,
        ...options.disableShowMore,
      };
      state.contributorOptionsWithGuests.selectedIds = options.selectedIds;
    },
    resetContributorOptionsWithGuests(state) {
      state.contributorOptionsWithGuests = { options: {}, disableShowMore: {}, selectedIds: [] };
    },
    resetContributorOptions(state) {
      state.contributorOptions = { options: {}, disableShowMore: {}, assignedToFieldOptions: [], selectedIds: [] };
    },
    setVerticalNav(state, navState) {
      state.isVerticalNav = navState;
    },
    setDashboardView(state, dashboardView) {
      state.dashboardView = dashboardView;
    },
    setSelectedCompanies(state, companies) {
      state.selectedCompanies = companies;
    },
    setUserCompanies: (state, userCompanies) => {
      state.userCompanies = userCompanies;
    },
    setErrorMessage: (state, errorMessage) => {
      (state.errorMessage = errorMessage);
    },
    setMspCustomFormModule: (state, mspCustomFormModule) => {
      (state.mspCustomFormModule = mspCustomFormModule);
    },
    setCategories: (state, categories) => {
      (state.categories = categories);
    },
    setDepartment: (state, departments) => {
      (state.departments = departments);
    },
    setCustomFormOptions: (state, options) => {
      state.customFormOptions = options;
    },
    setDefaultCategories: (state, categories) => {
      (state.defaultCategories = categories);
    },
    setDefaultDepartments: (state, departments) => {
      state.sefaultDepartments = departments;
    },
    setCurrentCompanyUser: (state, user) => {
      state.currentCompanyUser = user;
    },
    setSyncingConnectors: (state, connectors) => {
      state.syncingConnectors = connectors;
    },
    setLocations: (state, locations) => {
      state.locations.splice(0, state.locations.length, ...locations);
    },
    setTotalLocationsCount(state, totalLocationsCount) {
      state.totalLocationsCount = totalLocationsCount;
    },
    updateLocations(state, locations) {
      state.locations = locations;
    },
    setMfaSettings: (state, settings) => {
      state.mfaSettings = settings;
    },
    setSecureGroups: (state, secureGroups) =>{
      state.secureGroups = secureGroups;
    },
    setAwsRegions: (state, regions) => {
      (state.awsRegions = regions);
    },
    setCurrentVendor: (state, vendor) => {
      state.currentVendor = vendor;
    },
    setNestedModalOpened: (state, status) => {
      state.nestedModalOpened = status;
    },
    setRobotStatus: (state, isRobot) =>{
      state.isRobot = isRobot;
    },
    setIsEntityCreated: (state, isEntityCreated) =>{
      state.isEntityCreated = isEntityCreated;
    },
    setDomain: (state, domain) => {
      state.domain = domain;
    },
    setReroutePath: (state, path) => {
      state.reroutePath = path;
    },
    setCompany: (state, company) => {
      state.company = company;
    },
    setLocationLoader: (state, locationLoader) => {
      state.locationLoader = locationLoader;
    },
    setStringInterpolationKeys: (state, keys) => {
      state.stringInterpolationKeys = keys;
    },
    setCompanyFilter: (state, companyFilter) => {
      state.companyFilter = companyFilter;
    },
    setMspData(state, mspData) {
      state.mspData[mspData.item] = mspData.data;
    },
    setQuickBuildData(state, quickBuildData) {
      state.quickBuildData[quickBuildData.item] = quickBuildData.data;
    },
    setLoadedMspData(state, loadedMspData) {
      state.loadedMspData[loadedMspData.item] = loadedMspData.flag;
    },
    setCompanyTemplates: (state, companyTemplates) => { state.companyTemplates = companyTemplates; },
    setLoadingTemplates: (state, loadingTemplates) => { state.loadingTemplates = loadingTemplates; },
    setCardData(state, payload) {
      state.selectedCardData = payload.selectedItems;
    },
    setIntUserData(state, userData) {
      state.intUserData = userData;
    },
  },
  getters: {
    workspaceOptions: (state) => state.workspaceOptions,
    contributorOptions: (state) => state.contributorOptions,
    contributorOptionsWithGuests: (state) => state.contributorOptionsWithGuests,
    isVerticalNav: state => state.isVerticalNav,
    companyFilter: state => state.companyFilter,
    dashboardView: (state) => state.dashboardView,
    selectedCompanies: (state) => state.selectedCompanies,
    userCompanies: (state) => state.userCompanies,
    mspCustomFormModule: (state) => state.mspCustomFormModule,
    errorMessage: (state) => state.errorMessage,
    customFormOptions: (state) => state.customFormOptions,
    categories: (state) => state.categories,
    departments: (state) => state.departments,
    defaultCategories: (state) => state.defaultCategories,
    defaultDepartments: (state) => state.defaultDepartments,
    currentCompanyUser: (state) => state.currentCompanyUser,
    mfaSettings: (state) => state.mfaSettings,
    secureGroups: (state) => state.secureGroups,
    awsRegions: (state) => state.awsRegions,
    currentVendor: state => state.currentVendor,
    nestedModalOpened: state => state.nestedModalOpened,
    isRobot: state => state.isRobot,
    isEntityCreated: state => state.isEntityCreated,
    runCaptcha: state => state.runCaptcha,
    recaptchaSiteKey: state => state.recaptchaSiteKey,
    totalLocationsCount: state => state.totalLocationsCount,
    domain: state => state.domain,
    reroutePath: state => state.reroutePath,
    company: state => state.company,
    locations: state => state.locations,
    locationLoader: state => state.locationLoader,
    stringInterpolationKeys: state => state.stringInterpolationKeys,
    syncingConnectors: state => state.syncingConnectors,
    mspData: state => state.mspData,
    quickBuildData: state => state.quickBuildData,
    loadedMspData: state => state.loadedMspData,
    companyTemplates: state => state.companyTemplates,
    loadingTemplates: state => state.loadingTemplates,
    selectedCardData: state => state.selectedCardData,
    intUserData: state => state.intUserData,
  },
  actions: {
    async setupOptionsPusher({ commit }, payload) {
      const pusher = await Vue.prototype.$pusher;
      const channel = pusher.subscribe(Vue.prototype.$currentCompanyGuid);
      if (payload.type === 'contributorOptions') {
        channel.bind('contributor_options', data => {
          if (data.is_cache_reset) {
            commit('resetContributorOptions');
            commit('resetContributorOptionsWithGuests');
          }
        });
      } else if (payload.type === 'workspaceOptions') {
        channel.bind('workspace_options', data => {
          if (data.is_cache_reset) {
            commit('setWorkspaceOptions', []);
          }
        });
      }
    },
    fetchWorkspaces({ commit }) {
      http
        .get(`/workspace_options.json`)
        .then((res) => {
          commit('setWorkspaceOptions', res.data);
        })
        .catch((error) => {
          this.emitError(`Sorry, there was an error loading workspaces. (${error.response.data.message}).`);
        });
    },
    fetchUserCompanies({ commit }) {
      return http
        .get("/company_options.json")
        .then((res) => {
          commit("setUserCompanies", res.data.filter(c => c.subdomain !== 'sample'));
        })
        .catch(() => {
          this.emitError('Sorry, there was an error loading your companies.');
        });
    },
    fetchCustomFormOptions({ commit }, params) {
      return http
        .get(`/custom_form_options.json`, { params })
        .then(res => {
          commit('setCustomFormOptions', res.data);
        })
        .catch(() => {
          this.emitError('Sorry, there was an error loading forms. Please try again later.');
        });
    },
    fetchCategories({ commit }) {
      return http
        .get("/categories.json")
        .then((res) => {
          commit("setCategories", res.data);
        })
        .catch(() => {
          commit(
            "setError",
            `Sorry, there was an error gathering categories data. Please refresh the page and try again.`
          );
        });
    },
    fetchDefaultCategories({ commit }) {
      http
        .get(`/default_categories.json`)
        .then((res) => {
          commit("setDefaultCategories", res.data);
        })
        .catch(() => {
          commit("setError", `Sorry, there was an error fetching default categories. Please refresh the page and try again.`);
        });
    },
    fetchDepartments({ commit }) {
      return http
        .get('/departments.json')
        .then((res) => {
          commit("setDepartment", res.data.companyDepartments);
        })
        .catch(() => {
          commit('Sorry, there was an error fetching company departments. Please refresh the page and try again.');
        });
    },
    fetchCurrentCompanyUser({ commit }, params ) {
      http
        .get(`/current_company_user.json`, { params })
        .then((res) => {
          commit("setCurrentCompanyUser", res.data);
        })
        .catch((error) => {
          commit(
            "setErrorMessage",
            `Sorry, there was an error loading user (${error.response.data.message}).`
          );
        });
    },
    fetchMfaSettings({ commit }, companyId) {
      const url = "/mfa/settings.json";
      const params = { company_id: companyId };
      return http
        .get(url, { params })
        .then((res) => {
          if (res.data.mfaSettings) {
            commit("setMfaSettings", res.data.mfaSettings);
            commit("setSecureGroups", res.data.secureGroups);
          }
        })
        .catch(() => {
          commit("setError", `Sorry, there was an error while loading two factor authentication status.`);
        });
    },
    resetMfaSettings({ commit }) {
      commit("setMfaSettings", defaultStates.mfaSettings());
    },
    fetchAwsRegions({ commit }) {
      http
        .get("/integrations/aws_assets/regions.json")
        .then((res) => {
          commit("setAwsRegions", res.data.awsRegions);
        })
        .catch(() => {
          commit("setError", `Sorry, there was an error gathering aws regions. Please refresh the page and try again.`);
        });
    },
    fetchVendor({ commit, rootState }, id) {
      commit('setLoadingStatus', true);
      return http
        .get(`/vendors/${id}.json`)
        .then(res => {
          commit('setCurrentVendor', res.data);
          rootState.loadingStatus = false;
          rootState.loading = false;
        });
    },
    fetchStringInterpolationKeys({ commit }, type) {
      return http
        .get('/string_interpolation_keys.json', { params: { type } })
        .then((res) => {
          commit('setStringInterpolationKeys', res.data);
        });
    },
    fetchLocations({ commit }, params={}) {
      commit("setLocationLoader", true);
      http
        .get('/location_options.json', { params })
        .then((res) => {
          commit("setLocations", res.data);
          if (res.data.length > 0) {
            commit("setTotalLocationsCount", res.data[0].totalLocationsCount);
          }
        })
        .catch((error) => {
          const msg = _get(error, 'response.data.message', "");
          commit("setErrorMessage", `Sorry, there was an error loading location info. ${msg}`);
        })
        .finally(() => {
          commit("setLocationLoader", false);
        });
    },
    fetchMspAssetTypes({commit}, params={}) {
      return http
        .get(`/msp/templates/asset_types.json`, { params })
        .then((res) => {
          if (params.excludes_ids) {
            commit('setQuickBuildData', { data: res.data.mspAssetTypes, item: "assetTypes" });
          } else {
            commit('setMspData', { data: res.data.mspAssetTypes, item: "assetTypes" });
          }
          commit('setLoadedMspData', { flag: true, item: "fetchMspAssetTypes" });
        });
    },
    fetchMspBlockedKeywords({commit}, params={}) {
      return http
        .get(`/msp/templates/blocked_keywords.json`, { params })
        .then((res) => {
          if (params.excludes_ids) {
            commit('setQuickBuildData', { data: res.data.mspBlockedKeywords, item: "blockedKeywords" });
          } else {
            commit('setMspData', { data: res.data.mspBlockedKeywords, item: "blockedKeywords" });
          }
          commit('setLoadedMspData', { flag: true, item: "fetchMspBlockedKeywords" });
        });
    },
    fetchMspCategories({commit}, params={}) {
      return http
        .get(`/msp/templates/categories.json`, { params })
        .then((res) => {
          if (params.excludes_ids) {
            commit('setQuickBuildData', { data: res.data.mspCategories, item: "categories" });
          } else {
            commit('setMspData', { data: res.data.mspCategories, item: "categories" });
          }
          commit('setLoadedMspData', { flag: true, item: "fetchMspCategories" });
        });
    },
    fetchMspDocuments({commit}, params={}) {
      return http
        .get(`/msp/templates/library_documents.json`, { params })
        .then((res) => {
          if (params.excludes_ids) {
            commit('setQuickBuildData', { data: res.data.mspTemplatesDocuments, item: "documents" });
          } else {
            commit('setMspData', { data: res.data.mspTemplatesDocuments, item: "documents" });
          }
          commit('setLoadedMspData', { flag: true, item: "fetchMspDocuments" });
        });
    },
    fetchUsers({commit}) {
      return http
        .get(`/msp/templates/company_users.json`)
        .then((res) => {
          commit('setMspData', { data: res.data.companyUsers, item: "people" });
          commit('setLoadedMspData', { flag: true, item: "fetchUsers" });
        });
    },
    fetchMspSnippets({commit}, params={}) {
      return http
        .get(`/msp/templates/snippets.json`, { params })
        .then((res) => {
          if (params.excludes_ids) {
            commit('setQuickBuildData', { data: res.data.mspSnippets, item: "responses" });
          } else {
            commit('setMspData', { data: res.data.mspSnippets, item: "responses" });
          }
          commit('setLoadedMspData', { flag: true, item: "fetchMspSnippets" });
        });
    },
    fetchMspCustomForms({commit}, params={}) {
      return http
        .get(`/msp/templates/custom_forms.json`, { params })
        .then((res) => {
          if (params.excludes_ids) {
            commit('setQuickBuildData', { data: res.data.mspCustomForms, item: "customForms" });
          } else {
            commit('setMspData', { data: res.data.mspCustomForms, item: "customForms" });
          }
          commit('setLoadedMspData', { flag: true, item: "fetchMspCustomForms" });
        });
    },
    fetchMspFaqs({commit}, params={}) {
      return http
        .get(`/msp/templates/faqs.json`, { params })
        .then((res) => {
          if (params.excludes_ids) {
            commit('setQuickBuildData', { data: res.data.mspTemplatesHelpdeskFaqs, item: "faqs" });
          } else {
            commit('setMspData', { data: res.data.mspTemplatesHelpdeskFaqs, item: "faqs" });
          }
          commit('setLoadedMspData', { flag: true, item: "fetchMspFaqs" });
        });
    },
    fetchMspGroups({commit}) {
      return http
        .get(`/msp/templates/groups.json`)
        .then((res) => {
          commit('setMspData', { data: res.data.mspGroups, item: "groups" });
          commit('setLoadedMspData', { flag: true, item: "fetchMspGroups" });
        });
    },
    fetchMspAutomatedTasks({commit}, params={}) {
      return http
        .get(`/msp/templates/automated_tasks.json`, { params })
        .then((res) => {
          if (params.excludes_ids) {
            commit('setQuickBuildData', { data: res.data, item: "automatedTasks" });
          } else {
            commit('setMspData', { data: res.data, item: "automatedTasks" });
          }
          commit('setLoadedMspData', { flag: true, item: "fetchMspAutomatedTasks" });
        });
    },
    fetchCompanyTemplates({commit}) {
      return http
        .get('/company_builds.json')
        .then((res) => {
          commit('setCompanyTemplates', res.data.companyBuilds);
          commit('setLoadingTemplates', false);
        });
    },
    async fetchAssetSelectedDataCall({ commit }) {
      await http
        .get('/asset_preferences.json')
        .then((res) => {
          const { cardData } = res.data;
          commit('setCardData', cardData);
        })
        .catch((error) => {
          commit('setErrorMessage', `Sorry, there was an error fetching asset preferences (${error.message}).`);
        });
    },
  },
};
