<template>
  <sweet-modal
    ref="mfaPhoneModal"
    v-sweet-esc
    title="Edit Phone Number"
  >
    <div class="mfa-outer mt-2">
      <a
        v-if="verificationInProcess"
        class="text-secondary mfa-back mfa-back-modal"
        href="#"
        role="button"
        @click="goBack"
      >
        <i class="nulodgicon-arrow-left-c white mr-2" />
        <strong class="text-muted">Back</strong>
      </a>
      <div class="mfa-block text-secondary">
        <img
          src="https://nulodgic-static-assets.s3.amazonaws.com/images/mfa-logo.svg"
          class="mb-3"
        >
        <h4>
          Let's setup your phone!
        </h4>
      </div>
      <div v-if="!verificationInProcess">
        <p class="text-muted sub-text pb-3">
          Please provide new phone number to receive the verification code.
        </p>
        <div class="clearfix" />
      </div>
      <div v-else-if="verificationInProcess">
        <p class="text-muted sub-text pb-3">
          To continue, please enter the 6-digit verification code sent to 
          <strong>your phone ending in {{ phoneSlicer() }}</strong>.
        </p>
      </div>
      <div
        v-if="!verificationInProcess"
        class="form-group"
      >
        <phone-number-input
          class="mt-5"
          :model="phoneModel"
          @updated-model="updatePhone"
        />
        <div class="form-group mt-2">
          <button
            ref="verifyPhoneButton"
            :key="componentKey"
            class="btn btn-primary"
            :disabled="!mfaPhoneNumber.phoneNumber || isloading"
            @click="sendVerificationCode"
          >
            Verify Phone Number
          </button>
        </div>
      </div>
      <div
        v-else-if="verificationInProcess"
        class="form-group"
      >
        <div>
          <label
            for="mfaCode"
            class="text-secondary mt-3"
          >
            Your Verification Code
          </label>
          <verification-input
            ref="codeInput"
            @submit="focusVerifyButton"
          />
          <span
            v-if="error"
            class="form-text small text-danger text-left mt-1"
          >{{ error }}</span>
        </div>
        <div class="form-group mt-2">
          <button
            ref="verifyCodeButton"
            class="btn btn-primary"
            :disabled="isloading"
            @click.prevent="verifyMfaCode"
            @keydown.backspace="focusVerificationInput"
          >
            Verify Code
          </button>
        </div>
        <div class="text-muted small mt-2">
          <span v-if="resendCodeRequested && !error">
            Sending code ...
          </span>
          <span v-else>
            Didn't receive a code?
            <a
              href="#"
              @click="resendVerificationCode"
            >
              <i class="genuicon-refresh" />
              Resend
            </a>
          </span>
        </div>
      </div>
    </div>
  </sweet-modal>
</template>

<script>
import http from "common/http";
import { SweetModal } from "sweet-modal-vue";
import mfa from 'mixins/mfa_specific';
import permissionsHelper from 'mixins/permissions_helper';
import PhoneNumberInput from "../shared/phone_number_input.vue";
import VerificationInput from '../shared/verification_input.vue';

export default {
  components: {
    PhoneNumberInput,
    SweetModal,
    VerificationInput,
  },
  mixins: [mfa, permissionsHelper],
  props: {
    userId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      mfaCode: null,
      error: null,
      verificationInProcess: false,
      resendCodeRequested: false,
      componentKey: 0,
      isloading: false,
    };
  },
  methods: {
    onWorkspaceChange() {
      this.verificationInProcess = false;
    },
    phoneModel() {
      return {
        phone: "",
        countryCode: "",
        countryCodeNo: "",
      };
    },
    verifyMfaCode() {
      this.isloading = true;
      this.error = false;
      let mfa_code = this.$refs.codeInput.getValue();
      http
        .post(`/mfa/registration.json`, { mfa: mfa_code })
        .then(() => {
          this.updateRegisteredDevice();
          this.error = false;
          this.emitSuccess("Phone number updated successfully.");
          this.closeMfaPhoneModal();
          this.$emit("updated", this.mfaPhoneNumber);
        })
        .catch(error => {
          this.error = `${error.response.data.message}`;
        })
        .finally(() => {
          this.isloading = false;
          this.$nextTick(() => {
            this.focusVerifyButton();
          });
        });
    },
    sendVerificationCode() {
      const params = { registered_device: this.smsNumber() };
      this.sendCodeViaText(params);
      this.verificationInProcess = true;
      this.$nextTick(() => {
        this.$refs.codeInput.focusFirstInput();
      });
    },
    updateRegisteredDevice() {
      const params = {
        id: this.userId,
        mobile_phone: this.mfaPhoneNumber.phoneNumber,
        country_code: this.mfaPhoneNumber.phoneNumberCountryCode,
        country_code_number: this.mfaPhoneNumber.phoneNumberCountryCodeNumber,
      };
      http
        .post(`/mfa/edit_registered_device.json`, params)
        .then(() => {})
        .catch(error => {
          this.emitError(
            `Sorry, there was an error updating company security status: ${error.response.data.message}`
          );
        });
    },
    resetValues() {
      this.verificationInProcess = false;
      this.resendCodeRequested = false;
      this.mfaCode = null;
    },
    openMfaPhoneModal() {
      this.$refs.mfaPhoneModal.open();
    },
    closeMfaPhoneModal() {
      this.$refs.mfaPhoneModal.close();
    },
    resendVerificationCode() {
      this.error = false;
      this.mfaCode = null;
      this.$refs.codeInput.reset();
      this.resendCodeRequested = true;
      this.sendVerificationCode();
    },
    focusVerifyButton() {
      this.$refs.verifyCodeButton.focus();
    },
    focusVerificationInput(e) {
      this.$refs.codeInput.focusLastInput(e);
    },
    goBack() {
      this.verificationInProcess = false;
    },
  },
};
</script>

<style lang="scss" scoped>
  :deep(.sweet-modal) {
    max-width: 500px;
    width: 100%;

    .sweet-title {
      h2 {
        font-size: 20px;
      }
    }

    .mfa-outer {
      box-shadow: none;
      border: 0;
      padding: 0;

      img {
        max-width: 60px;
      }
    }
  }
</style>
